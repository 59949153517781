/** @jsx jsx */
import { jsx, Heading, Text } from "theme-ui"
import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Wide from "../components-styled/Wide"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import PictureSet from "../components/PictureSet"
import imgSet03 from "../imgsets/imgset03"

const Spacer = () => <span sx={{ mx: 2 }}></span>

const Events = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  let events = data.allMdx.group[0].edges
  if (data.allMdx.group.length === 2) {
    const pins = data.allMdx.group[1].edges
    events = [...pins, ...events]
  }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Veranstaltungen" />

      <Wide>
        <PictureSet imgSet={imgSet03} />
      </Wide>

      <Heading as="h3" sx={{ fontSize: 3, mt: 4, mb: 2 }}>
        Gründerzertifikat
      </Heading>
      <p>
        Gründungsinteressierte haben die Möglichkeit, durch den Besuch von
        hgnc-Veranstaltungen und -Workshops ein Zertifikat von hgnc@hgnc.de zu
        erhalten. Voraussetzung ist die Teilnahme an mindestens 5
        hgnc-Veranstaltungen in einem Zeitraum von 2 Jahren. Wichtig ist, dass
        man die Teilnahme durch Einträge auf den Teilnehmerlisten nachweisen
        kann.
      </p>

      <Heading as="h1" sx={{ fontSize: [8, 8, 8, 8, 1], mt: 4, mb: 3 }}>
        Veranstaltungen
      </Heading>

      {events.map(({ node }) => {
        const title = node.frontmatter.title
        const anchor = node.fields.slug
          .replace(/\/veranstaltungen\//g, "")
          .replace(/\//g, "")

        return (
          <article key={node.fields.slug} sx={{ mt: 4 }}>
            <Link to={node.fields.slug} id={anchor}>
              <header>
                <Text>
                  {node.frontmatter.date}
                  <Spacer />
                  {node.frontmatter.time}
                  <Spacer />
                  {node.frontmatter.location}
                  <Spacer />
                  {node.frontmatter.pin && (
                    <FontAwesomeIcon
                      icon={["fas", "thumbtack"]}
                      rotation={90}
                    />
                  )}
                </Text>
                <Heading as="h2" sx={{ fontSize: 2, mb: 2 }}>
                  {node.frontmatter.title}
                </Heading>
                {node.frontmatter.subtitle && (
                  <Heading as="h3" sx={{ fontSize: 3, mb: 2 }}>
                    {node.frontmatter.subtitle}
                  </Heading>
                )}
              </header>
              <section>
                <p
                  dangerouslySetInnerHTML={{
                    __html: node.frontmatter.description || node.excerpt,
                  }}
                />
              </section>
            </Link>
          </article>
        )
      })}
    </Layout>
  )
}

export default Events

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      filter: { fileAbsolutePath: { regex: "/veranstaltungen/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      group(field: frontmatter___pin) {
        edges {
          node {
            excerpt
            fields {
              slug
            }
            frontmatter {
              pin
              date(formatString: "DD. MMMM YYYY")
              time
              title
              subtitle
              description
              location
              station
            }
          }
        }
      }
    }
  }
`
